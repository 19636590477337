import React from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { useParams } from "@reach/router"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"
import ItemNotFound from "./ItemNotFound"
import Loading from "../../Shared/Loading"
import Error from "../../Shared/Error"
import ShopItemTitle from "./ShopItemTitle"
import ShopItemImages from "./ShopItemImages"
import ShopItemPrices from "./ShopItemPrices"

const ShopItemInsert = ({ itemNumber, classes }) => {
  const params = useParams()
  const id = itemNumber
  var userType = "guest"
  const { sbuId } = useSiteMetadata()

  const { data, loading, error } = useQuery(SHOP_ITEM_QUERY, {
    variables: {
      id: id,
      userType: userType,
      sbuId: sbuId,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data.shopItem === null) return <ItemNotFound />

  const item = data.shopItem
  const itemImage = data.shopItem.itemImage
  return (
    <>
      <div className={classes.root}>
        <Grid container alignItems="flex-start" spacing={3}>
          <Grid item xs={12}>
            <ShopItemPrices item={item} />
            {/* <Typography
              component="h1"
              className={classes.itemPageHeader}
              color="inherit"
              gutterBottom
            >
              {item.name}
            </Typography> */}
          </Grid>
          {/* <Grid item xs={12}>
              <Box className={classes.itemImage}>
                <ShopItemImages />
              </Box>
            </Grid> */}

          {/* <Grid item xs={12}>
            <ShopItemTitle item={item} />
          </Grid> */}
          <Grid item xs={12}>
            <Typography>{JSON.stringify(data)}</Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1, 0, 0),
  },
  container: {
    display: "grid",
  },
  itemImage: {
    height: 420,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
  itemPageHeader: {
    fontSize: "28px",
    letterSpacing: "1px",
    // fontWeight: "bold",
    textTransform: "uppercase",
  },
})

const SHOP_ITEM_QUERY = gql`
  query($id: Int, $userType: String, $sbuId: Int) {
    shopItem(id: $id, userType: $userType, sbuId: $sbuId) {
      id
      name
      category {
        id
        name
        description
      }
      businessUnit {
        id
        name
      }
      isActive
      pricingModel {
        id
        description
      }
      customItemField {
        id
        name
        description
        isActive
      }
      itemPrice {
        id
        minQuantity
        maxQuantity
        price
        name
      }
      itemImage {
        id
        image
        imageUrl
        carouselImageUrl
        title
        note
      }
      specItem {
        specId
        summary
        unitWeightEach
        weightUnits
        unitHeightEach
        unitWidthEach
        unitLengthEach
        dimensionalUnits
      }
    }
  }
`

export default withStyles(styles)(ShopItemInsert)

import React, { useRef, useState, Fragment } from "react"
import Layout from "../components/Layout/layout"
import ItemSide from "../components/Layout/ItemSide"
import {
  Box,
  ButtonBase,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import withStyles from "@material-ui/core/styles/withStyles"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import TinyGallery from "../components/Gallery/TinyGallery"
import FeatureCheckList from "../components/DropIn/FeatureCheckList"
import FrequentlyAsked from "../components/DropIn/FrequentlyAsked"
import QuoteBox from "../components/DropIn/QuoteBox"
import { getThemeProps } from "@material-ui/styles"
import Inquiry from "../components/Inquiry"
import ShopItemInsert from "../components/Shop/ShopItem/ShopItemInsert"
import ShopHeader from "../components/Shop/ShopHeader"
import ImageGallery from "../components/DropIn/ImageGallery"
const Product001 = ({ data, classes }) => {
  const inquirySection = useRef()
  const [selectedOption, setSelectedOption] = useState("B")
  const scrollToInquiry = () =>
    inquirySection.current.scrollIntoView({ behavior: "smooth" })
  const {
    mdx: {
      frontmatter: { title, subtitle, slug },
      body,
    },
  } = data

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  // Exported objects and arrays from the body of the mdx file

  const description = data.mdx.exports.description
  const featureList = data.mdx.exports.featureList
  const quotes = data.mdx.exports.quotes
  const faq = data.mdx.exports.faq
  const details = data.mdx.exports.details
  const notes = data.mdx.exports.notes
  const productInfo = data.mdx.exports.productInfo
  const { price, subheader, options, itemNumber } = productInfo
  const img01 = data.mdx.frontmatter.image01.childImageSharp.fluid
  const img02 = data.mdx.frontmatter.image02.childImageSharp.fluid
  const img03 = data.mdx.frontmatter.image03.childImageSharp.fluid
  const images = [img01, img02, img03]
  const [value, setValue] = useState("")
  // const [fieldValues, setFieldValues] = useState(
  //   options.customFields.map(customField => customField)
  // )
  const handleChange = event => {
    setValue(event.target.value)
  }
  // const handleFieldChange = event => {
  //   setCustomFieldValues(customFieldValues => [
  //     ...customFieldValues,
  //     event.target.value,
  //   ])
  // }
  return (
    <Layout>
      <Container className={classes.container}>
        <ShopHeader title={title} />
        <Grid container spacing={3}>
          <Grid item xxs={12} xs={12} md={12} sm={12} md={8}>
            {/* <Image
              fluid={img01}
              alt={`${title} by ICON Emblem`}
              className={classes.itemImage}
            /> */}
            <ImageGallery images={images} />
          </Grid>
          <Grid item xxs={12} xs={12} sm={12} md={4}>
            <Typography
              component="h1"
              className={classes.productPageHeader}
              color="inherit"
            >
              {title}
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
              {subtitle}
            </Typography>
            <Typography variant="body1" className={classes.price}>
              ${financial(price)}
            </Typography>
            {subheader &&
              subheader.map((subhead, index) => (
                <Fragment key={index}>
                  <Typography variant="body1" className={classes.subhead}>
                    {subhead}
                  </Typography>
                </Fragment>
              ))}
            <MDXRenderer>{body}</MDXRenderer>
          </Grid>

          <Grid item container>
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <Box width="85%">
                {description.map((desc, index) => (
                  <Box key={index}>
                    <Typography variant="body1" className={classes.summary}>
                      {desc}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xxs={12} xs={12} sm={12} md={6}>
              <Box width="85%">
                <Typography variant="body1" className={classes.summary}>
                  Details
                </Typography>
                <List>
                  {details.map((detail, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      {detail}
                    </ListItem>
                  ))}
                </List>
                <Typography variant="body1" className={classes.summary}>
                  Notes
                </Typography>
                <List>
                  {notes &&
                    notes.map((note, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <ArrowRightIcon />
                        </ListItemIcon>
                        {note}
                      </ListItem>
                    ))}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query GetSingleProduct2($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        subtitle
        category
        date(formatString: "MMMM Do, YYYY")
        slug
        image01 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image02 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image03 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      id
      body
      exports {
        description
        details
        notes
        faq {
          id
          question
          answer
        }
        featureList {
          id
          headline
          icon
          copy
        }
        productInfo {
          price
          subheader
          options {
            itemNumber
            name
            description
            price
            customFields
          }
        }
      }
    }
  }
`
const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),

    // margin: theme.spacing(1, 0, 0),
  },
  container: {
    marginTop: 15,
    // display: "grid",
  },
  itemImage: {
    maxWidth: "100%",
  },
  productPageHeader: {
    fontSize: "32px",
    letterSpacing: "2px",
    // fontWeight: "bold",
    textTransform: "uppercase",
  },
  tagline: {
    fontSize: "32px",
    letterSpacing: "3px",
    // fontWeight: "bold",
    textTransform: "uppercase",
  },
  price: {
    fontSize: "32px",
    letterSpacing: "1px",
  },
  summary: {
    fontSize: "18px",
    letterSpacing: "0.1px",
    marginTop: theme.spacing(1),
    lineHeight: "150%",
  },
  subhead: {
    fontSize: "15px",
    marginTop: theme.spacing(1),
    lineHeight: "90%",
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
  commonButton: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    width: "100%",
    // margin: theme.spacing(1),
    fontSize: "18px",
    textTransform: "uppercase",
    letterSpacing: "2px",
  },
  mdxBox: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  inquiryFrame: {
    paddingTop: theme.spacing(4),
  },
  subtitle: {
    fontSize: "20px",
  },
})
export default withStyles(styles)(Product001)

import React, { useState, useContext, Fragment } from "react"
import { navigate } from "gatsby"
import withStyles from "@material-ui/core/styles/withStyles"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import Typography from "@material-ui/core/Typography"
import FolderIcon from "@material-ui/icons/Folder"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import { green, purple } from "@material-ui/core/colors"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { Alert, AlertTitle } from "@material-ui/lab"

const ShopItemTitle = ({
  classes,
  item,
  expandedPrePro,
  setExpandedPrePro,
}) => {
  //   const { state, dispatch } = useContext(UserContext)

  const handleFocusPrePro = () => {
    setExpandedPrePro(true)
  }
  return (
    <div className={classes.root}>
      <Card square className={classes.paper} variant="outlined">
        <Grid container>
          <Grid item xs={12} sm={9}>
            <Typography variant="h5" className={classes.title}>
              {item.name ? item.name : "unnamed item"}
            </Typography>

            <Typography variant="h6" className={classes.title}>
              {item.category && item.category.name
                ? item.category.name
                : "unspecified category"}
            </Typography>
            <Box ml={1}>
              <Typography variant="body1" className={classes.title}>
                Something here
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box pt={2} pb={2}>
              <Alert
                variant="outlined"
                severity="info"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={handleFocusPrePro}
                  >
                    View
                  </Button>
                }
              >
                <AlertTitle variant="caption">
                  Your attention is required
                </AlertTitle>
              </Alert>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
  },
  title: {},
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none",
  },
})

export default withStyles(styles)(ShopItemTitle)

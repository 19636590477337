import React from "react"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
// import MobileStepper from "@material-ui/core/MobileStepper"
// import Paper from "@material-ui/core/Paper"
// import Typography from "@material-ui/core/Typography"
// import Button from "@material-ui/core/Button"
// import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
// import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {
  AppBar,
  Avatar,
  Button,
  Tabs,
  Tab,
  Box,
  ButtonBase,
  Paper,
  Typography,
} from "@material-ui/core"
// import SwipeableViews from "react-swipeable-views"
// import { autoPlay } from "react-swipeable-views-utils"
import Image from "gatsby-image"
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 1000,
    flexGrow: 1,
    padding: theme.spacing(1, 0, 1, 0),
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 520,
    display: "block",
    // maxWidth: 1000,
    overflow: "hidden",
    width: "100%",
  },
  imageButton: {
    height: "40px",
  },
  // buttonImage: {
  //   height: "40px",
  // },
  listImage: {
    marginLeft: "auto",
    marginRight: "auto",
    // width: "100%",
    height: "100%",
    // objectFit: "contain",
    borderRadius: "10px",
  },
  buttonImage: {
    width: "60px",
    height: "60px",
    display: "flex",
    // width: "auto",
    // minHeight: "40px",
    // maxWidth: "100px",
    // maxHeight: "80px",
    // marginBottom: theme.spacing(2),
  },
  imageBox: {
    width: "60px",
    height: "60px",
    display: "flex",
    // border: "1px solid #d3d3d3",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "rgba(0,0,0,0.5)",

    // width: "auto",
    // minHeight: "40px",
    // maxWidth: "100px",
    // maxHeight: "80px",
    // marginBottom: theme.spacing(2),
  },
  tabs: {
    padding: theme.spacing(1, 0, 1, 0),
    height: "90px",
  },
  overlay: {
    position: "absolute",
    // display: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255, 0.6)",
    zIndex: 2,
    curson: "pointer",
    transition: ".5s ease",
  },
}))

const GalleryTabs = withStyles(theme => ({
  root: {
    borderBottom: theme.palette.common.black,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 90,
      width: "100%",
      backgroundColor: "#d3d3d3",
    },
  },
}))(Tabs)

const GalleryTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    width: "90px",
    fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />)

const ImageGallery = ({ images }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = images.length
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setActiveStep(newValue)
  }
  return (
    <div className={classes.root}>
      {/* <Paper square elevation={0} className={classes.header}>
        <Typography>{tutorialSteps[activeStep].label}</Typography>
      </Paper> */}
      <Slider {...settings}>
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Image
                fluid={step}
                // alt={`${title} by ICON Emblem`}
                // className={classes.itemImage}
              />
            ) : null}
          </div>
        ))}
      </Slider>

      {/* <Paper square elevation={0} p={2}>
        <Typography variant="caption">put info here</Typography>
      </Paper> */}
      {/* <AppBar position="static" color="default"> */}
      <div className={classes.tabs}>
        <GalleryTabs
          value={activeStep}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {images.map((img, index) => (
            <GalleryTab
              key={index}
              label="Item One"
              component={() => (
                <ButtonBase disableRipple onClick={() => setActiveStep(index)}>
                  <Box
                    className={activeStep === index ? "" : classes.overlay}
                  />
                  <Box className={classes.imageBox}>
                    <Image
                      fluid={img}
                      // alt={`${title} by ICON Emblem`}
                      className={classes.buttonImage}
                      imgStyle={{
                        maskImage: "#ffffff",
                      }}
                    />
                  </Box>
                </ButtonBase>
              )}
            />
          ))}
        </GalleryTabs>
      </div>
      {/* </AppBar> */}
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
    </div>
  )
}

export default ImageGallery

import React from "react"
import { Breadcrumbs, Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import { Link } from "gatsby"

const ShopHeader = ({ classes, title }) => {
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/products" className={classes.link}>
          Our Products
        </Link>
        <Link color="inherit" to="/shop" className={classes.link}>
          Shop
        </Link>
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs>
    </div>
  )
}
const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
})
export default withStyles(styles)(ShopHeader)

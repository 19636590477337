import React, { useState } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import MobileStepper from "@material-ui/core/MobileStepper"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
// import SwipeableViews from "react-swipeable-views"
// import { autoPlay } from "react-swipeable-views-utils"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import { ButtonBase } from "@material-ui/core"
import Draggable from "react-draggable"

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const ShopItemImages = ({ classes, itemImage }) => {
  const images = itemImage
  const [activeStep, setActiveStep] = useState(0)
  const [open, setOpen] = useState(false)
  const maxSteps = images.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Paper square elevation={0} className={classes.header} variant="outlined">
        {/* <Box padding={1}>
          <Typography variant="overline" display="block" gutterBottom>
            {images && images[activeStep].title && images[activeStep].title}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {images &&
              images[activeStep].note &&
              `Note: ${images[activeStep].note}`}
          </Typography>
        </Box> */}
        {/* </Paper> */}

        {/* Dialog image pop-up */}
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          maxWidth={false}
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {images && images[activeStep].title}
          </DialogTitle>
          <DialogContent>
            <img
              src={images && images[activeStep].imageUrl}
              alt={
                images && images[activeStep].title
                  ? images && images[activeStep].title
                  : "Item Image"
              }
            />
            <DialogContentText>
              {images && images[activeStep].note}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              variant="outlined"
              color="secondary"
            >
              Close
            </Button>
            <Button
              href={images ? `${images[activeStep].imageUrl}` : undefined}
              download={images ? `${images[activeStep].image}` : undefined}
              variant="contained"
              color="primary"
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Dialog image pop-up */}
        {/* <AutoPlaySwipeableViews
          axis={"x-reverse"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={6000}
        >
          {images &&
            images.map((step, index) => (
              <div key={step.id}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <ButtonBase onClick={handleClickOpen}>
                    <img
                      className={classes.img}
                      src={step.carouselImageUrl}
                      alt={step.title}
                    />
                  </ButtonBase>
                ) : null}
              </div>
            ))}
        </AutoPlaySwipeableViews> */}
        {itemImage.length > 1 && (
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        )}
      </Paper>
    </div>
  )
}

const styles = theme => ({
  root: {
    // maxWidth: 300,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    flexGrow: 1,
  },
  paper: {
    // padding: theme.spacing(2),
    boxShadow: "none",
    backgroundColor: theme.palette.background.primary,
  },
  header: {
    // display: 'flex',
    alignItems: "center",

    // paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.primary,
  },
  img: {
    // height: 255,
    display: "block",
    maxWidth: 285,
    overflow: "hidden",
    width: "100%",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
})

export default withStyles(styles)(ShopItemImages)

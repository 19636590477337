import React, { useState, useContext, Fragment } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import { Link } from "gatsby"

import Button from "@material-ui/core/Button"
// import { useCart } from "../../UserCart/Cart"
import Paper from "@material-ui/core/Paper"

import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"

import ListItemText from "@material-ui/core/ListItemText"
import { Alert, AlertTitle } from "@material-ui/lab"
import { ShoppingCart as ShoppingCartIcon } from "react-feather"

const ShopItemPrices = ({ classes, item }) => {
  const [itemQty, setItemQty] = useState({})
  const [success, setSuccess] = useState(false)
  //   const { addItem } = useCart()

  const id = item.id ? item.id : undefined
  const name = item.name ? item.name : undefined
  const prices = item.itemPrice ? item.itemPrice : undefined
  const weightUnits =
    item.specItem && item.specItem.weightUnits
      ? item.specItem.weightUnits
      : undefined
  const weightEach =
    item.specItem && item.specItem.unitWeightEach
      ? item.specItem.unitWeightEach
      : undefined

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  function calcTotal(a, b) {
    //Return the sum
    return (a * b).toFixed(2)
  }

  function handleAddToCart(prices) {
    const price = Number.parseFloat(`${prices.price}`)
    const qty = Number.parseFloat(`${prices.minQuantity}`)

    const cartItem = {
      id: Number.parseFloat(`${id}`),
      name: `${name}`,
      price: price,
      quantity: qty,
      weightEach: weightEach,
      weightUnits: weightUnits,
    }
    // addItem(cartItem)
    setSuccess(true)
  }

  return (
    <>
      <div className={classes.root}>
        <Paper elevation={0} className={classes.paper} variant="outlined">
          <Typography variant="h4" gutterBottom>
            {item.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            This item uses quantity-based pricing. Select an order quantity to
            add the item to your cart.
          </Typography>
          <>
            {success === true && (
              <Alert
                variant="outlined"
                severity="success"
                onClose={() => {
                  setSuccess(false)
                }}
              >
                <AlertTitle>Item added to cart</AlertTitle>
                <Button
                  size="small"
                  startIcon={<ShoppingCartIcon />}
                  component={Link}
                  to={"/my/cart/"}
                >
                  Go to Cart
                </Button>
              </Alert>
            )}
            <List>
              {prices &&
                prices.map(p => {
                  const qty = p.minQuantity
                  const price = financial(p.price)
                  return (
                    <Fragment key={`fragment ${p.id}`}>
                      <Divider key={`divider ${p.id}`} />
                      <ListItem
                        key={p.id}
                        button
                        onClick={e => handleAddToCart(p)}
                      >
                        <ListItemText
                          key={`text ${p.id}`}
                          primary={`${qty} pcs for ${price} each`}
                        />
                        <ListItemAvatar key={`avatar ${p.id}`} edge="end">
                          <ShoppingCartIcon />
                        </ListItemAvatar>
                      </ListItem>
                    </Fragment>
                  )
                })}
            </List>
            <Divider />
          </>
        </Paper>
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      // margin: theme.spacing(0),
      // padding: theme.spacing(0),
      // width: theme.spacing(16),
      // height: theme.spacing(16),
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none",
  },
  table: {
    maxWidth: 500,
  },
})

export default withStyles(styles)(ShopItemPrices)
